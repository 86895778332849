import { DestinazioneImmobile, MotiviAnnullamentoPratica, MotiviFermoPratica, StatiPratica, UbicazioniChiavi } from "../const/pratica-const";
import { AnagraficaSocieta } from "./anagrafica-societa";
import { AnagraficaUtente } from "./anagrafica-utente";
import { Documento } from "./documento";
import { LogPratica } from "./log-pratica";
import { MessaggioChat } from "./messaggio-chat";
import { Persona } from "./persona";
import { RTIModel } from "./rti";
import { StoricoRTI } from "./storticoRti";

export class Pratica {
    id: number = 0;
    numerazione: string = "";
    indirizzo: string = "";
    citta: string = "";
    provincia: string = "";
    cap: string = "";
    scala: string = "";
    piano: string = "";
    ubicazioneChiavi: UbicazioniChiavi = UbicazioniChiavi.AGENZIA;
    amministratore: string = "";
    telefonoAmministratore: string = "";
    emailAmministratore: string = "";
    numeroVani: number = 0;
    conformitaCatasto: boolean = false;
    dataScadenzaMandato: string = "";
    importo: number = 0;
    stato: StatiPratica = StatiPratica.NUOVA;
    dataCreazione: string = "";
    dataModifica: string = "";
    dataAnnuncio: string = "";
    prezzo: number = 0;
    brochureTitolo: string = "";
    speseCondominiali: number = 0;
    superficieNetta: number = 0;
    superficieCommerciale: number = 0;
    dataScadenzaApe: string = "";
    sopraluogo: boolean = false;
    conformitaCatastale: boolean = false;
    dataSopraluogo: string = "";
    dataFermoPratica: string = "";
    motivoFermoPratica: MotiviFermoPratica = MotiviFermoPratica.NON_SPECIFICATO;
    motivoAnnullamentoPratica: MotiviAnnullamentoPratica = MotiviAnnullamentoPratica.SCADUTO_INCARICO;
    visibileAcquirenteAlTecnico: boolean = false;
    brochureDescrizione: string = "";
    brochureIndirizzoMaps: string = "";
    renditaCatastale: number = 0;
    categoriaCatastale: string = "";
    hasLogs: boolean = false;
    volturaLuce_nuovaInstallazione: boolean = false;
    volturaLuce_residente: boolean = false;
    volturaLuce_contatorePOD: string = "";
    volturaLuce_potenzaRichiesta: number = 0;
    volturaLuce_denominazioneSocieta: string = "";
    volturaLuce_nome: string = "";
    volturaLuce_cognome: string = "";
    volturaLuce_telefono: string = "";
    volturaLuce_CF: string = "";
    volturaLuce_PIVA: string = "";
    volturaLuce_destinazioneImmobile: DestinazioneImmobile = DestinazioneImmobile.ABITAZIONE;
    volturaLuce_indirizzo: string = "";
    volturaLuce_CAP: string = "";
    volturaLuce_citta: string = "";
    volturaLuce_provincia: string = "";
    volturaLuce_regione: string = "";
    volturaLuce_modalitaRecapitoFattura: boolean = false;
    volturaLuce_emailRecapitoBollettaFattura: string = "";
    volturaLuce_modalitaPagamentoFattura: boolean = false;
    volturaLuce_intestatarioConto: string = "";
    volturaLuce_banca: string = "";
    volturaLuce_IBAN: string = "";
    volturaLuce_swift: string = "";
    volturaLuce_residenza_indirizzo: string = "";
    volturaLuce_residenza_CAP: string = "";
    volturaLuce_residenza_citta: string = "";
    volturaLuce_residenza_provincia: string = "";
    volturaLuce_residenza_regione: string = "";
    volturaLuce_residenza_stato: string = "";
    volturaGas_nuovaInstallazione: boolean = false;
    volturaGas_residente: boolean = false;
    volturaGas_contatorePDR: string = "";
    volturaGas_destinazioneGas: string = "";
    volturaGas_denominazioneSocieta: string = "";
    volturaGas_nome: string = "";
    volturaGas_cognome: string = "";
    volturaGas_telefono: string = "";
    volturaGas_CF: string = "";
    volturaGas_PIVA: string = "";
    volturaGas_destinazioneImmobile: DestinazioneImmobile = DestinazioneImmobile.ABITAZIONE;
    volturaGas_indirizzo: string = "";
    volturaGas_CAP: string = "";
    volturaGas_citta: string = "";
    volturaGas_provincia: string = "";
    volturaGas_regione: string = "";
    volturaGas_modalitaRecapitoFattura: boolean = false;
    volturaGas_emailRecapitoBollettaFattura: string = "";
    volturaGas_modalitaPagamentoFattura: boolean = false;
    volturaGas_intestatarioConto: string = "";
    volturaGas_banca: string = "";
    volturaGas_IBAN: string = "";
    volturaGas_swift: string = "";
    volturaGas_residenza_indirizzo: string = "";
    volturaGas_residenza_CAP: string = "";
    volturaGas_residenza_citta: string = "";
    volturaGas_residenza_provincia: string = "";
    volturaGas_residenza_regione: string = "";
    volturaGas_residenza_stato: string = "";
    societaId: number = 0;
    societa: AnagraficaSocieta = new AnagraficaSocieta();
    tecnicoId: string | null = null;
    tecnico: AnagraficaUtente | null = null;
    consulenteId: string = "";
    consulente: AnagraficaUtente = new AnagraficaUtente();
    persone: Persona[] = [];
    logs: LogPratica[] = [];
    documenti: Documento[] = [];
    messaggi: MessaggioChat[] = [];
    datiRTI: RTIModel | null = null;
    datiRTIId: number | null = null;
    storicoRti: StoricoRTI[] = [];
}