import { Location } from '@angular/common';
import { ApplicationRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Params, Router } from '@angular/router';
import { faComments, faEnvelope, faFile, faFolder, faMap, faTrashCan, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo, faCircleXmark, faClockRotateLeft, faDownload, faFileInvoice, faFire, faGraduationCap, faHome, faHouse, faPenFancy, faPlus, faPlusCircle, faSuitcase, faUsers } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CampiForm, DestinazioneImmobile, MotiviAnnullamentoLabel, MotiviFermoLabel, MotiviFermoPratica, RTI_TipoProtocolloData, StatiPratica, TipiPersone, UbicazioniChiavi } from 'src/app/const/pratica-const';
import { TipiDocumento, TipiLabel } from 'src/app/const/tipi-documento';
import { Documento } from 'src/app/models/documento';
import { Persona } from 'src/app/models/persona';
import { Pratica } from 'src/app/models/pratica';
import { PrepPratica } from 'src/app/models/prep-pratica';
import { AuthService } from 'src/app/services/auth.service';
import { PraticheService } from 'src/app/services/pratiche.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Utils } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HostListener } from '@angular/core';
import { ComponentCanDeactivate } from 'src/app/helpers/pending-changes-guard';
import { GetPraticaResult } from 'src/app/models/get-pratica-result';
import { LogPratica } from 'src/app/models/log-pratica';
import { AnagraficaUtente } from 'src/app/models/anagrafica-utente';
import { RTIModel, RTIProtocolloData } from 'src/app/models/rti';

@Component({
  selector: 'app-pratica-form',
  templateUrl: './pratica-form.component.html',
  styleUrls: ['./pratica-form.component.scss']
})
export class PraticaFormComponent implements ComponentCanDeactivate {
  faHouse = faHouse;
  faSuitcase = faSuitcase;
  faFire = faFire;
  faEnvelope = faEnvelope;
  faMap = faMap;
  faComments = faComments;
  faCircleInfo = faCircleInfo;
  faTrashCan = faTrashCan;
  faCircleXmark = faCircleXmark;
  faPlus = faPlus;
  faUser = faUser;
  faUsers = faUsers;
  faFolder = faFolder;
  faGraduationCap = faGraduationCap;
  faFile = faFile;
  faPenFancy = faPenFancy;
  faClockRotateLeft = faClockRotateLeft;
  faDownload = faDownload;
  activeTab = 0;
  edit = false;
  TipiDocumento = TipiDocumento;
  TipiLabel = TipiLabel;
  MotiviFermoLabel = MotiviFermoLabel;
  DestinazioneImmobile = DestinazioneImmobile;
  CampiForm = CampiForm;
  RTI_TipoProtocolloData = RTI_TipoProtocolloData;
  prat: Pratica = new Pratica();
  logs: LogPratica[] = [];
  prep: PrepPratica = new PrepPratica();
  rogito: boolean = false;
  stato: string = "";
  proprietari: Persona[] = [];
  acquirenti: Persona[] = [];
  referenti: Persona[] = [];
  documentiPratica: Documento[][] = [];
  notaio: Persona = new Persona();
  referenteNotaio: Persona = new Persona();
  volturaLuce: string = "cambio";
  invioMailTesto: string = "";
  invioMailIndirizzo: string = "";
  valoriDocumentiDaInviare = [
    { id: 1, name: "Tutti i documenti" }
  ];
  selectedDocumentiDaInviare = [];
  statoIniziale = StatiPratica.NUOVA;
  chatRefreshInterval: any;
  messaggioChat = "";
  statiPratica = StatiPratica;
  motiviFermoPratica = MotiviFermoPratica;
  motiviAnnullamentoLabel = MotiviAnnullamentoLabel;
  formDirty = false;
  statoString = "";
  isSocietaInterna = false;
  isSocietaIbrida = false;
  tecnicoDisabled = false;
  showModalRogito = false;
  showModalAlertTecnico = false;
  showModalConsulente = false;
  showModalTecnicoConfirm = false;
  initialTecnicoId: string | null = "";
  datiModalConsulente = new AnagraficaUtente();
  tecnicoSelected = new AnagraficaUtente();
  showModalStoricoRTI = false;

  constructor(
    private toastr: ToastrService,
    private praticheSrv: PraticheService,
    private spinner: SpinnerService,
    private router: Router,
    private location: Location,
    public auth: AuthService,
    private route: ActivatedRoute,
    private appRef: ApplicationRef) {}

  canDeactivate(): Observable<boolean> | boolean {
    return !this.formDirty;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      if (this.formDirty) {
          $event.returnValue = true;
      }
  }

  ngOnInit() {
    this.spinner.show();
    var id = parseInt(this.route.snapshot.paramMap.get('id') ?? "0");
    var societaId = parseInt(this.route.snapshot.paramMap.get('societaId') ?? "0");
    this.praticheSrv.getPreparazionePratica(societaId, id).then((res: PrepPratica) => {
      this.prep = res;
      if(id != 0) {
        this.praticheSrv.getPraticaById(id).then((res2: GetPraticaResult) => {
          var consulenteId = res2.pratica.consulenteId;
          if(res2.pratica.datiRTI == null) {
            res2.pratica.datiRTI = new RTIModel();
            res2.pratica.datiRTIId = 0;
          }
          res2.pratica.datiRTI.protocolliDataDoubleArray = [];
          this.prat = res2.pratica;
          this.logs = res2.logs;
          this.isSocietaInterna = res2.isSocietaInterna;
          this.isSocietaIbrida = res2.isSocietaIbrida;
          this.edit = true;
          this.rogito = this.prat.stato == StatiPratica.ROGITATA;
          this.statoString = Utils.getStatoString(this.prat.stato);
          this.init2();
          this.prat.consulenteId = consulenteId;
          if(this.route.snapshot.paramMap.get("rogitoAlert") == "true") {
            this.showModalRogito = true;
          }
          if(this.route.snapshot.paramMap.get("tecnicoAlert") == "true" && !this.auth.isTecnico) {
            this.showModalAlertTecnico = true;
          }
        }).catch((err) => {
          console.log(err);
          this.spinner.dismiss();
          this.toastr.error("C'è stato un errore, contatta l'assistenza.");
          history.back();
        });
      } else {
        this.prat = new Pratica();
        this.prat.datiRTI = new RTIModel();
        this.prat.datiRTIId = 0;
        this.statoString = Utils.getStatoString(this.prat.stato);
        if(this.prep.listaTecnici.length > 0) {
          this.prat.tecnicoId = this.prep.listaTecnici[0].id;
        }
        if(this.prep.isSocietaInterna && !this.prep.isSocietaIbrida && this.prep.listaTecnici.length > 0) {
          var studioFound = false;
          this.prep.listaTecnici.forEach((tec) => {
            if(tec.nome == "Studio Capasso") {
              studioFound = true;
              this.prat.tecnicoId = tec.id;
            }
          });
          if(!studioFound) {
            this.prat.tecnicoId = this.prep.listaTecnici[0].id;
          }
        }
        this.initialTecnicoId = this.prat.tecnicoId;
        this.changeTecnico();
        this.init2();
      }
    }).catch((err: any) => {
      console.log(err);
      this.spinner.dismiss();
      this.toastr.error("C'è stato un errore, contatta l'assistenza.");
      history.back();
    });
    document.getElementById("praticaForm")?.addEventListener("change", (event) => {
      var targetName = (event.target as any).name;
      if(targetName != "invioMailTesto" && targetName != "invioMailIndirizzo") {
        this.formDirty = true;
      }
    });
  }

  init2() {
    this.statoIniziale = this.prat.stato;
    this.spinner.dismiss();
    this.prat.consulenteId = this.prep.listaConsulenti[0].id;
    this.prat.societaId = this.prep.societa.id;
    this.stato = Utils.getStatoString(this.prat.stato);
    this.proprietari = this.prat.persone.filter((p) => p.tipoPersona == TipiPersone.PROPRIETARIO);
    this.proprietari.forEach((p => {
      p.documentiCF = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.CF);
      p.documentiIdentita = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.PR);
      p.documentiPrivacy = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.PRIV);
    }));
    this.referenti = this.prat.persone.filter((p) => p.tipoPersona == TipiPersone.REFERENTE_PROPRIETA);
    this.referenti.forEach((p => {
      p.documentiCF = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.CF);
      p.documentiIdentita = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.PR);
      p.documentiPrivacy = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.PRIV);
    }));
    this.acquirenti = this.prat.persone.filter((p) => p.tipoPersona == TipiPersone.ACQUIRENTE);
    this.acquirenti.forEach((p => {
      p.documentiCF = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.CF);
      p.documentiIdentita = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.PR);
      p.documentiPrivacy = p.documenti.filter((d) => d.tipoDocumento == TipiDocumento.PRIV);
    }));
    if(this.prat.persone.filter((p) => p.tipoPersona == TipiPersone.NOTAIO).length > 0) {
      this.notaio = this.prat.persone.filter((p) => p.tipoPersona == TipiPersone.NOTAIO)[0];
      this.notaio.documentiCF = [];
      this.notaio.documentiIdentita = [];
      this.notaio.documentiPrivacy = [];
    } else {
      this.notaio.tipoPersona = TipiPersone.NOTAIO;
    }
    if(this.prat.persone.filter((p) => p.tipoPersona == TipiPersone.REFERENTE_NOTAIO).length > 0) {
      this.referenteNotaio = this.prat.persone.filter((p) => p.tipoPersona == TipiPersone.REFERENTE_NOTAIO)[0];
      this.referenteNotaio.documentiCF = [];
      this.referenteNotaio.documentiIdentita = [];
      this.referenteNotaio.documentiPrivacy = [];
    } else {
      this.referenteNotaio.tipoPersona = TipiPersone.REFERENTE_NOTAIO;
    }
    this.prat.documenti.forEach((d) => {
      if(typeof(this.documentiPratica[d.tipoDocumento]) === "undefined") {
        this.documentiPratica[d.tipoDocumento] = [];
      }
      this.documentiPratica[d.tipoDocumento].push(d);
    });
    
    const tipoProtocolliKeys = Object.values(RTI_TipoProtocolloData).filter((v) => !isNaN(Number(v)));
    tipoProtocolliKeys.forEach((e) => {
      this.prat.datiRTI!.protocolliDataDoubleArray[Number(e)] = [];
    })
    this.prat.datiRTI!.protocolliData.forEach((e) => {
      this.prat.datiRTI!.protocolliDataDoubleArray[e.tipo].push(e);
    });
    this.prat.datiRTI!.documentiPlanimetrie = this.prat.datiRTI!.documenti.filter((d) => d.tipoDocumento == TipiDocumento.RTI_PLAN);

    
    this.prat.tecnicoId ??= "";
    if(this.edit) {
      this.prat.documenti.forEach((d) => {
        this.valoriDocumentiDaInviare.push({id: d.id, name: TipiLabel[d.tipoDocumento]});
      });
      this.prat.persone.forEach((p) => {
        p.documenti.forEach((d) => {
          this.valoriDocumentiDaInviare.push({id: d.id, name: p.nome + ": " + TipiLabel[d.tipoDocumento]});
        })
      });
    }
    this.chatRefreshInterval = setInterval(() => {
      if(this.prat.id != 0 && this.activeTab == 5) {
        this.refreshChat();
      }
    }, 10000);
    setTimeout(() => {
      this.logs.forEach((log) => {
        log.campiModificati.forEach((campo) => {
          document.querySelector("[data-campo='"+campo+"']")?.classList.add("logged");
        });
      });
    }, 1000);
    this.valoriDocumentiDaInviare = [...this.valoriDocumentiDaInviare]; // Per forzare l'aggiornamento del componente ng-select
    this.tecnicoDisabled = this.prep.isSocietaInterna && !this.auth.isAdmin && !(this.auth.isTecnico && this.auth.isTecnicoInterno);
    if(this.tecnicoDisabled && this.prep.listaTecnici.filter((tec) => !tec.tecnico_interno).length > 0) {
      this.tecnicoDisabled = false;
    }
  }

  submit(tecnicoConfirmed = false) {
    if(!this.edit && this.prat.tecnicoId == this.initialTecnicoId && this.prep.listaTecnici.length > 1 && (this.tecnicoSelected.nome != "Studio Capasso" || this.prep.isSocietaIbrida) && !tecnicoConfirmed) {
      this.showModalTecnicoConfirm = true;
      return;
    }
    this.prat.persone = [];
    this.prat.persone = this.prat.persone
    .concat(this.proprietari)
    .concat(this.referenti)
    .concat(this.acquirenti)
    .concat(this.notaio)
    .concat(this.referenteNotaio);
    this.prat.persone.forEach((pers) => {
      pers.documenti = [];
      pers.documenti = pers.documenti
      .concat(pers.documentiCF ?? [])
      .concat(pers.documentiIdentita ?? [])
      .concat(pers.documentiPrivacy ?? []);
    });

    this.prat.documenti = [];
    this.documentiPratica.forEach((elem) => {
      this.prat.documenti = this.prat.documenti.concat(elem);
    });
    this.prat.documenti.forEach((doc) => {
      doc.tipoDocumento = Number(doc.tipoDocumento);
    });
    var files = [];
    var filesFields = document.getElementsByClassName("uploadFiles") as any;
    for(let i=0; i < filesFields.length; i++) {
      if(filesFields[i].files.length > 0) {
        files.push(filesFields[i].files[0]);
      }
    }
    this.prat.ubicazioneChiavi = Number(this.prat.ubicazioneChiavi);
    this.prat.stato = Number(this.prat.stato);
    this.prat.motivoFermoPratica = Number(this.prat.motivoFermoPratica);
    this.prat.volturaLuce_destinazioneImmobile = Number(this.prat.volturaLuce_destinazioneImmobile);
    this.prat.volturaGas_destinazioneImmobile = Number(this.prat.volturaGas_destinazioneImmobile);
    this.prat.volturaLuce_potenzaRichiesta = Number(this.prat.volturaLuce_potenzaRichiesta);
    if(this.auth.isTecnico) {
      var valid = this.setupRTIForSubmit();
      if(!valid) { return; }
    }
    this.spinner.show();
    if(this.edit) {
      this.praticheSrv.editPratica(this.prat, files).then((res) => {
        this.spinner.dismiss();
        this.toastr.success("Pratica salvata con successo");
        this.formDirty = false;
        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
          this.router.navigate(['pratica-form/' + this.prat.id]);
        });
      }).catch(() => {
        this.spinner.dismiss();
        this.toastr.error("Errore salvataggio pratica");
      });
    } else {
      this.praticheSrv.createPratica(this.prat, files).then((res) => {
        this.spinner.dismiss();
        this.toastr.success("Pratica salvata con successo");
        this.formDirty = false;
        var navParams: any = {};
        if(this.prat.tecnicoId != null) {
          if(this.prat.documenti.filter((doc) => doc.tipoDocumento == TipiDocumento.ATNOT_AGENZIA).length == 0 && this.prat.tecnicoId.length > 0) {
            navParams.rogitoAlert = "true";
          }
        }
        if(this.prat.tecnicoId?.length == 0) {
          navParams.tecnicoAlert = "true";
        }
        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
          this.router.navigate(['pratica-form/' + res.id, navParams]);
        });
      }).catch(() => {
        this.spinner.dismiss();
        this.toastr.error("Errore salvataggio pratica");
      });
    }
  }

  addProprietario() {
    var newPersona = new Persona();
    newPersona.tipoPersona = TipiPersone.PROPRIETARIO;
    this.proprietari.push(newPersona);
    this.formDirty = true;
  }

  addAcquirente() {
    var newPersona = new Persona();
    newPersona.tipoPersona = TipiPersone.ACQUIRENTE;
    this.acquirenti.push(newPersona);
    this.formDirty = true;
  }

  deleteProprietario(i: number) {
    var prop = this.proprietari[i];
    if(prop.documentiCF.length > 0 || prop.documentiIdentita.length > 0 || prop.documentiPrivacy.length > 0|| prop.email.length > 0 || prop.nome.length > 0 || prop.telefono.length > 0) {
      if(!confirm("Cancellando questo proprietario saranno eliminati anche i dati e i suoi documenti. Confermi?")) {
        return;
      }
    }
    this.proprietari[i].documentiCF.forEach((d, index) => {
      this.deleteDocumentoPersona("proprietario", TipiDocumento.CF, i, index, true);
    });
    this.proprietari[i].documentiIdentita.forEach((d, index) => {
      this.deleteDocumentoPersona("proprietario", TipiDocumento.PR, i, index, true);
    });
    this.proprietari[i].documentiPrivacy.forEach((d, index) => {
      this.deleteDocumentoPersona("proprietario", TipiDocumento.PRIV, i, index, true);
    });
    this.proprietari.splice(i, 1);
    this.formDirty = true;
  }

  deleteAcquirente(i: number) {
    var acquirente = this.acquirenti[i];
    if(acquirente.documentiCF.length > 0 || acquirente.documentiIdentita.length > 0 || acquirente.documentiPrivacy.length > 0|| acquirente.email.length > 0 || acquirente.nome.length > 0 || acquirente.telefono.length > 0) {
      if(!confirm("Cancellando questo acquirente saranno eliminati anche i dati e i suoi documenti. Confermi?")) {
        return;
      }
    }
    this.acquirenti[i].documentiCF.forEach((d, index) => {
      this.deleteDocumentoPersona("acquirente", TipiDocumento.CF, i, index, true);
    });
    this.acquirenti[i].documentiIdentita.forEach((d, index) => {
      this.deleteDocumentoPersona("acquirente", TipiDocumento.PR, i, index, true);
    });
    this.acquirenti[i].documentiPrivacy.forEach((d, index) => {
      this.deleteDocumentoPersona("acquirente", TipiDocumento.PRIV, i, index, true);
    });
    this.acquirenti.splice(i, 1);
    this.formDirty = true;
  }

  addReferenteProprieta() {
    var newPersona = new Persona();
    newPersona.tipoPersona = TipiPersone.REFERENTE_PROPRIETA;
    this.referenti.push(newPersona);
    this.formDirty = true;
  }

  deleteReferenteProprieta(i: number) {
    var ref = this.referenti[i];
    if(ref.email.length > 0 || ref.nome.length > 0 || ref.telefono.length > 0) {
      if(!confirm("Cancellando questo referente saranno eliminati anche i suoi dati. Confermi?")) {
        return;
      }
    }
    this.referenti.splice(i, 1);
    this.formDirty = true;
  }

  aggiungiDocumentoPersona(persona: string, tipo: TipiDocumento, index: number) {
    var input=document.createElement('input');
    input.type="file";
    input.className = "uploadFiles";
    input.accept = "application/pdf";
    input.onchange = (event: any) => {
      var file = event.target.files[0];
      if(file) {
        var ext = (file.name as string).split(".").at(-1);
        var estensioniPermesse = ["pdf"];
        if(estensioniPermesse.indexOf(ext.toLowerCase()) > -1) {
          var documento = new Documento();
          documento.filename = file.name;
          documento.tipoDocumento = tipo;
          switch(persona) {
            case "proprietario":
              switch(tipo) {
                case TipiDocumento.CF:
                  this.proprietari[index].documentiCF.push(documento);
                  break;
                case TipiDocumento.PR:
                  this.proprietari[index].documentiIdentita.push(documento);
                  break;
                case TipiDocumento.PRIV:
                  this.proprietari[index].documentiPrivacy.push(documento);
                  break;
              }
              break;
            case "acquirente":
              switch(tipo) {
                case TipiDocumento.CF:
                  this.acquirenti[index].documentiCF.push(documento);
                  break;
                case TipiDocumento.PR:
                  this.acquirenti[index].documentiIdentita.push(documento);
                  break;
                case TipiDocumento.PRIV:
                  this.acquirenti[index].documentiPrivacy.push(documento);
                  break;
              }
              break;
          }
        } else {
          this.toastr.error("Formato file non permesso. Carica un documento PDF.");
          input.value = "";
        }
      }
    };
    document.getElementById("hidden-input-file")?.appendChild(input);
    setTimeout(function(){
      input.click();
    },200);
    this.formDirty = true;
  }

  deleteDocumentoPersona(persona: string, tipo: TipiDocumento, indexPersona: number, indexDocumento: number, bypassConfirm: boolean = false) {
    if(!bypassConfirm) {
      if(!confirm("Confermi di voler eliminare questo documento?")) {
        return;
      }
    }
    var nomeFile = "";
    switch(persona) {
      case "proprietario":
        switch(tipo) {
          case TipiDocumento.PR:
            nomeFile = this.proprietari[indexPersona].documentiIdentita[indexDocumento].filename;
            this.proprietari[indexPersona].documentiIdentita.splice(indexDocumento, 1);
            break;
          case TipiDocumento.CF:
            nomeFile = this.proprietari[indexPersona].documentiCF[indexDocumento].filename;
            this.proprietari[indexPersona].documentiCF.splice(indexDocumento, 1);
            break;
          case TipiDocumento.PRIV:
            nomeFile = this.proprietari[indexPersona].documentiPrivacy[indexDocumento].filename;
            this.proprietari[indexPersona].documentiPrivacy.splice(indexDocumento, 1);
            break;
        }
        break;
      case "acquirente":
        switch(tipo) {
          case TipiDocumento.PR:
            nomeFile = this.acquirenti[indexPersona].documentiIdentita[indexDocumento].filename;
            this.acquirenti[indexPersona].documentiIdentita.splice(indexDocumento, 1);
            break;
          case TipiDocumento.CF:
            nomeFile = this.acquirenti[indexPersona].documentiCF[indexDocumento].filename;
            this.acquirenti[indexPersona].documentiCF.splice(indexDocumento, 1);
            break;
          case TipiDocumento.PRIV:
            nomeFile = this.acquirenti[indexPersona].documentiPrivacy[indexDocumento].filename;
            this.acquirenti[indexPersona].documentiPrivacy.splice(indexDocumento, 1);
            break;
        }
        break;
    }
    var inputFields = document.querySelectorAll("#hidden-input-file input");
    var found = false;
    inputFields.forEach((input: any) => {
      if(input.files.length > 0) {
        if(input.files[0].name == nomeFile && !found) {
          input.remove();
          found = true;
        }
      }
    });
    this.formDirty = true;
  }

  aggiungiDocumentoPratica(tipo: TipiDocumento) {
    var input=document.createElement('input');
    input.type="file";
    input.className = "uploadFiles";
    var documentiImmagini = [TipiDocumento.COPBRC, TipiDocumento.IMGS]
    if(documentiImmagini.indexOf(tipo) != -1) {
      input.accept = ".png, .PNG, .jpg, .JPG, .jpeg, .JPEG";
    } else {
      input.accept = "application/pdf";
    }
    input.onchange = (event: any) => {
      var file = event.target.files[0];
      if(file) {
        var ext = (file.name as string).split(".").at(-1);
        var estensioniPermesse = ["pdf"];
        if(documentiImmagini.indexOf(tipo) != -1) {
          estensioniPermesse = ["jpeg", "jpg", "png"];
        }
        if(estensioniPermesse.indexOf(ext.toLowerCase()) > -1) {
          var documento = new Documento();
          documento.filename = file.name;
          documento.tipoDocumento = tipo;
          if(typeof(this.documentiPratica[tipo]) === "undefined") {
            this.documentiPratica[tipo] = [];
          }
          this.documentiPratica[tipo].push(documento);
        } else {
          this.toastr.error("Formato file non permesso. Carica un documento nelle seguenti estensioni: " + estensioniPermesse.join(" "));
          input.value = "";
        }
      }
    };
    document.getElementById("hidden-input-file")?.appendChild(input);
    setTimeout(function(){
      input.click();
    },200);
    this.formDirty = true;
  }

  deleteDocumentoPratica(tipo: TipiDocumento, i: number) {
    var nomeFile = this.documentiPratica[tipo][i].filename;
    this.documentiPratica[tipo].splice(i, 1);
    var inputFields = document.querySelectorAll("#hidden-input-file input");
    var found = false;
    inputFields.forEach((input: any) => {
      if(input.files.length > 0) {
        if(input.files[0].name == nomeFile && !found) {
          input.remove();
          found = true;
        }
      }
    });
    this.formDirty = true;
  }

  annulla() {
    this.router.navigate(["pratiche"]);
  }

  getDocumento(doc: Documento) {
    if(doc.id == 0) {
      var file: any;
      var inputFields = document.querySelectorAll("#hidden-input-file input");
      var found = false;
      inputFields.forEach((input: any) => {
        if(input.files.length > 0) {
          if(input.files[0].name == doc.filename && !found) {
            file = input.files[0];
            found = true;
          }
        }
      });
      if(file) {
        var url = URL.createObjectURL(file);
        window.open(url, "_blank");
      }
    } else {
      var form = document.createElement("form");
      var token = document.createElement("input"); 
      var userid = document.createElement("input");  
      var documentoId = document.createElement("input");  
  
      form.method = "POST";
      form.action = environment.API_URL + "pratiche/documento";   
  
      token.value = this.auth.token;
      token.name = "token";
      form.appendChild(token);  
  
      userid.value = this.auth.user.id;
      userid.name = "userid";
      form.appendChild(userid);
  
      documentoId.value = doc.id.toString();
      documentoId.name = "id";
      form.appendChild(documentoId); 
  
      form.style.display = "none";
      form.setAttribute("target", "_blank");
      form.setAttribute("enctype", "multipart/form-data");
      document.body.appendChild(form);
      form.submit();
    }
  }

  rogitoChanged(val: boolean) {
    if(val) {
      this.prat.stato = StatiPratica.ROGITATA;
    } else {
      if(this.statoIniziale == StatiPratica.ROGITATA) {
        this.prat.stato = StatiPratica.IN_LAVORAZIONE;
      } else {
        this.prat.stato = this.statoIniziale;
      }
    }
    this.stato = Utils.getStatoString(this.prat.stato);
    this.formDirty = true;
  }

  statoChanged(val: StatiPratica) {
    this.stato = Utils.getStatoString(this.prat.stato);
    this.formDirty = true;
  }

  setActiveTab(n: number) {
    this.activeTab = n;
    if(n == 5) {
      if(this.prat.id != 0) {
        this.refreshChat(true);
      }
    }
  }

  inviaMessaggio() {
    if(this.messaggioChat.trim().length > 0) {
      this.spinner.show();
      this.praticheSrv.sendMessaggioChat(this.messaggioChat, this.prat.id).then(() => {
        this.messaggioChat = "";
        this.refreshChat();
        this.spinner.dismiss();
      }).catch(() => {
        this.toastr.error("Errore con l'invio del messaggio");
        this.spinner.dismiss();
      });
    }
  }

  refreshChat(forceScrollToBottom = false) {
    var nMessaggiPrima = this.prat.messaggi.length;
    this.praticheSrv.getChat(this.prat.id).then((res) => {
      this.prat.messaggi = res;
      if(nMessaggiPrima != res.length || forceScrollToBottom) {
        setTimeout(() => {
          document.getElementsByClassName("messaggi")[0].scrollTop = document.getElementsByClassName("messaggi")[0].scrollHeight - document.getElementsByClassName("messaggi")[0].clientHeight;
        }, 200);
      }
    }).catch(() => { this.toastr.error("Errore nel recuperare i messaggi") });
  }

  inviaFilePratica() {
    if(!this.edit) {
      this.toastr.error("Prima di poter inviare i file devi salvare la nuova pratica");
      return;
    }
    this.spinner.show();
    var ids = new Array<number>();
    this.selectedDocumentiDaInviare.forEach((id: any) => {
      ids.push(id.id);
    });
    this.praticheSrv.inviaFilePratica(this.prat.id, ids, this.invioMailIndirizzo, this.invioMailTesto).then(() => {
      this.toastr.success("File inviati con successo");
      this.spinner.dismiss();
    }).catch((err) => {
      this.toastr.error("Errore con l'invio dei file pratica");
      this.spinner.dismiss();
    });
  }

  generaBrochure() {
    if(!this.edit || this.formDirty) {
      this.toastr.error("Prima di poter generare la brochure devi salvare la pratica");
      return;
    }

    var form = document.createElement("form");
    var token = document.createElement("input"); 
    var userid = document.createElement("input");  
    var id = document.createElement("input");  

    form.method = "POST";
    form.action = environment.API_URL + "pratiche/brochure";   

    token.value = this.auth.token;
    token.name = "token";
    form.appendChild(token);  

    userid.value = this.auth.user.id;
    userid.name = "userid";
    form.appendChild(userid);

    id.value = this.prat.id.toString();
    id.name = "id";
    form.appendChild(id); 

    form.style.display = "none";
    form.setAttribute("target", "_blank");
    form.setAttribute("enctype", "multipart/form-data");
    document.body.appendChild(form);
    form.submit();
  }

  ngOnDestroy() {
    if(this.chatRefreshInterval) {
      clearInterval(this.chatRefreshInterval);
    }
  }

  inviaRichiestaVoltura() {
    this.toastr.error("Funzionalità ancora da implementare. Contatta direttamente lo staff di PraticaOk.");
  }

  rogitoChat() {
    this.setActiveTab(5);
    this.messaggioChat = "Salve, avrei bisogno del rogito";
    this.showModalRogito = false;
  }

  openModalConsulente() {
    if(this.prat.consulenteId.length > 0) {
      this.prep.listaConsulenti.forEach((cons) => {
        if(cons.id == this.prat.consulenteId) {
          this.datiModalConsulente = cons;
        }
      });
    }
    this.showModalConsulente = true;
  }

  changeTecnico() {
    if(this.prep.listaTecnici.filter(t => t.id == this.prat.tecnicoId).length > 0) {
      this.tecnicoSelected = this.prep.listaTecnici.filter(t => t.id == this.prat.tecnicoId)[0];
    }
  }

  // RTI

  generaRTI() {
    var files = [];
    var filesFields = document.getElementsByClassName("uploadFiles") as any;
    for(let i=0; i < filesFields.length; i++) {
      if(filesFields[i].files.length > 0) {
        files.push(filesFields[i].files[0]);
      }
    }
    var valid = this.setupRTIForSubmit();
    if(!valid) { return; }
    this.spinner.show();
    this.praticheSrv.generaRti(this.prat.datiRTI!, files).then(res => {
      this.getRTI(res.id);
      this.prat.storicoRti.push(res);
    }).catch(err => {
      this.toastr.error("Errore generazione relazione tecnica integrata");
    }).finally(() => {
      this.spinner.dismiss();
    });
  }

  setupRTIForSubmit(): boolean {
    this.prat.datiRTI!.documenti = [];
    this.prat.datiRTI!.documenti = this.prat.datiRTI!.documenti.concat(this.prat.datiRTI!.documentiPlanimetrie);
    this.prat.datiRTI!.documenti.forEach((doc) => {
      doc.tipoDocumento = Number(doc.tipoDocumento);
    });
    this.prat.datiRTI!.protocolliData = [];
    this.prat.datiRTI!.protocolliDataDoubleArray.forEach(pd => {
      if(pd.length > 0) {
        this.prat.datiRTI!.protocolliData = this.prat.datiRTI!.protocolliData.concat(pd);
      }
    });
    if((this.prat.datiRTI!.certificatore_dataNascita.length > 0 && Utils.parseDataString(this.prat.datiRTI!.certificatore_dataNascita) == null) || (this.prat.datiRTI!.sopralluogo_effettuato && this.prat.datiRTI!.sopralluogo_data.length > 0 && Utils.parseDataString(this.prat.datiRTI!.sopralluogo_data) == null)) {
      this.toastr.error("Errore nel formato della data. Controlla i valori inseriti");
      return false;
    }
    var dataOk = true;
    this.prat.datiRTI!.protocolliData.forEach((pd => {
      if(Utils.parseDataString(pd.data) == null) {
        dataOk = false;
      }
    }));
    if(!dataOk) {
      this.toastr.error("Errore nel formato della data. Controlla i valori inseriti");
      return false;
    }
    return true;
  }

  addProtocolloData(tipo: RTI_TipoProtocolloData) {
    var newPD = new RTIProtocolloData();
    newPD.tipo = tipo;
    this.prat.datiRTI!.protocolliDataDoubleArray[tipo].push(newPD);
    console.log(this.prat.datiRTI!.protocolliDataDoubleArray[tipo]);
  }

  removeProtocolloData(tipo: RTI_TipoProtocolloData, i: number) {
    this.prat.datiRTI!.protocolliDataDoubleArray[tipo].splice(i, 1);
  }

  aggiungiDocumentoRTI(tipo: TipiDocumento) {
    var input=document.createElement('input');
    input.type="file";
    input.className = "uploadFiles";
    var documentiImmagini = [TipiDocumento.COPBRC, TipiDocumento.IMGS]
    if(documentiImmagini.indexOf(tipo) != -1) {
      input.accept = ".png, .PNG, .jpg, .JPG, .jpeg, .JPEG";
    } else {
      input.accept = "application/pdf";
    }
    input.onchange = (event: any) => {
      var file = event.target.files[0];
      if(file) {
        var ext = (file.name as string).split(".").at(-1);
        var estensioniPermesse = ["pdf"];
        if(documentiImmagini.indexOf(tipo) != -1) {
          estensioniPermesse = ["jpeg", "jpg", "png"];
        }
        if(estensioniPermesse.indexOf(ext.toLowerCase()) > -1) {
          var documento = new Documento();
          documento.filename = file.name;
          documento.tipoDocumento = tipo;
          if(tipo == TipiDocumento.RTI_PLAN) {
            this.prat.datiRTI!.documentiPlanimetrie.push(documento);
          }
        } else {
          this.toastr.error("Formato file non permesso. Carica un documento nelle seguenti estensioni: " + estensioniPermesse.join(" "));
          input.value = "";
        }
      }
    };
    document.getElementById("hidden-input-file")?.appendChild(input);
    setTimeout(function(){
      input.click();
    },200);
    this.formDirty = true;
  }

  deleteDocumentoRTI(tipo: TipiDocumento, i: number) {
    if(tipo == TipiDocumento.RTI_PLAN) {
      var nomeFile = this.prat.datiRTI!.documentiPlanimetrie[i].filename;
      this.prat.datiRTI!.documentiPlanimetrie.splice(i, 1);
    }
    
    var inputFields = document.querySelectorAll("#hidden-input-file input");
    var found = false;
    inputFields.forEach((input: any) => {
      if(input.files.length > 0) {
        if(input.files[0].name == nomeFile && !found) {
          input.remove();
          found = true;
        }
      }
    });
    this.formDirty = true;
  }

  getRTI(id: number) {
    var form = document.createElement("form");
    var token = document.createElement("input"); 
    var userid = document.createElement("input");  
    var documentoId = document.createElement("input");  

    form.method = "POST";
    form.action = environment.API_URL + "pratiche/getRTI";   

    token.value = this.auth.token;
    token.name = "token";
    form.appendChild(token);  

    userid.value = this.auth.user.id;
    userid.name = "userid";
    form.appendChild(userid);

    documentoId.value = id.toString();
    documentoId.name = "id";
    form.appendChild(documentoId); 

    form.style.display = "none";
    form.setAttribute("target", "_blank");
    form.setAttribute("enctype", "multipart/form-data");
    document.body.appendChild(form);
    form.submit();
  }

  apriStoricoRTI() {
    this.showModalStoricoRTI = true;
  }

  
}
