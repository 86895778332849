import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Stats } from 'src/app/models/stats-model';
import { PraticheService } from 'src/app/services/pratiche.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { faAward, faBox } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import ApexCharts from 'apexcharts';
import { ChartType } from 'ng-apexcharts';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatsComponent {

  stats = new Stats();
  anno = new Date().getFullYear();
  anniPraticheFerme: number[] = [new Date().getFullYear()];
  anniPraticheStati: number[] = [new Date().getFullYear()];
  annoPraticheFerme = new Date().getFullYear();
  annoPraticheStati = new Date().getFullYear();
  chartFerme = {
    series: [1,0,0,0,0,0,0,0,0,0],
    chart: {
      width: 300,
      height: 700,
      type: "pie" as ChartType
    },
    legend: {
      position: "bottom" as "right" | "left" | "top" | "bottom" | undefined,
      horizontalAlign: "left" as "left" | "center" | "right" | undefined,
    },
    labels: ["Non specificato", "Rifiuto del cliente", "L'agenzia non vuole che il cliente venga contattato", "Pratica insanabile", "Proprietario irraggiungibile", "Intervento di un altro tecnico", "Mancanza documenti forniti dall'agenzia", "Mancanza del numero di telefono del proprietario", "In attesa di risposta da parte del cliente", "In attesa di risposta da parte dell'agente", "Pratica caricata due volte"],
  };
  chartStati = {
    series: [1,0,0,0,0,0,0,0,0,0],
    chart: {
      width: 300,
      height: 700,
      type: "pie" as ChartType
    },
    legend: {
      position: "bottom" as "right" | "left" | "top" | "bottom" | undefined,
      horizontalAlign: "left" as "left" | "center" | "right" | undefined
    },
    labels: ["In lavorazione", "Conclusa", "Rogitata", "Ferma", "Nuova", "Annullata"],
  };

  faAward = faAward;
  faFile = faFile;
  faBox = faBox;

  constructor(
    private spinner: SpinnerService,
    private praticheSrv: PraticheService,
    private toastr: ToastrService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.praticheSrv.getStats().then((stats) => {
      this.stats = stats;
      this.stats.ferme.forEach((statsFermeAnno) => {
        if(this.anniPraticheFerme.indexOf(statsFermeAnno.anno) == -1) {
          this.anniPraticheFerme.push(statsFermeAnno.anno);
        }
      });
      this.stats.stati.forEach((statsStatiAnno) => {
        if(this.anniPraticheStati.indexOf(statsStatiAnno.anno) == -1) {
          this.anniPraticheStati.push(statsStatiAnno.anno);
        }
      });
      this.reloadCharts();
      this.spinner.dismiss();
    }).catch(() => {
      this.toastr.error("Errore nel recuperare le statistiche");
      this.spinner.dismiss();
    });
  }

  reloadCharts() {
    var fermeAnno = this.stats.ferme.filter((value) => value.anno == this.annoPraticheFerme);
    if(fermeAnno.length == 0) {
      this.chartFerme.series = [1,0,0,0,0,0,0,0,0,0,0];
    } else {
      var fermeAnnoSingle = fermeAnno[0];
      this.chartFerme.series = [fermeAnnoSingle.nonSpecificato, fermeAnnoSingle.rifiutoDelCliente, fermeAnnoSingle.agenziaNoContattoCliente, fermeAnnoSingle.insanabile, fermeAnnoSingle.irraggiungibile, fermeAnnoSingle.interventoAltroTecnico, fermeAnnoSingle.mancanzaDocumentiAgenzia, fermeAnnoSingle.mancanzaNumeroProprietario, fermeAnnoSingle.attesaRispostaCliente, fermeAnnoSingle.attesaRispostaAgente, fermeAnnoSingle.doppia];
      if(fermeAnnoSingle.nonSpecificato + fermeAnnoSingle.rifiutoDelCliente + fermeAnnoSingle.agenziaNoContattoCliente + fermeAnnoSingle.insanabile + fermeAnnoSingle.irraggiungibile + fermeAnnoSingle.interventoAltroTecnico + fermeAnnoSingle.mancanzaDocumentiAgenzia + fermeAnnoSingle.mancanzaNumeroProprietario + fermeAnnoSingle.attesaRispostaCliente + fermeAnnoSingle.attesaRispostaAgente + fermeAnnoSingle.doppia == 0) {
        this.chartFerme.series = [1,0,0,0,0,0,0,0,0,0,0];
      }
    }

    var statiAnno = this.stats.stati.filter((value) => value.anno == this.annoPraticheStati);
    if(statiAnno.length == 0) {
      this.chartStati.series = [1,0,0,0,0,0,0,0,0,0];
    } else {
      var statiAnnoSingle = statiAnno[0];
      this.chartStati.series = [statiAnnoSingle.inLavorazione, statiAnnoSingle.conclusa, statiAnnoSingle.rogitata, statiAnnoSingle.ferma, statiAnnoSingle.nuova, statiAnnoSingle.annullata];
      if(statiAnnoSingle.inLavorazione + statiAnnoSingle.conclusa + statiAnnoSingle.rogitata + statiAnnoSingle.ferma + statiAnnoSingle.nuova + statiAnnoSingle.annullata == 0) {
        this.chartStati.series = [1,0,0,0,0,0,0,0,0,0];
      }
    }
  }

}
